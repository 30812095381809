import $ from "../_snowpack/pkg/jquery.js";
import {getCookie} from "./helper.js";
import {themes, defaultTheme} from "./config.js";
function loadcss(name) {
  $("head").append('<link rel="stylesheet" href="/css/' + name + '.css">');
}
function main() {
  if (getCookie("theme") == "") {
    var theme = "index." + defaultTheme;
    loadcss(theme);
  } else {
    if (themes.includes(getCookie("theme"))) {
      loadcss(getCookie("theme"));
    } else {
      var themeerror = "theme " + getCookie("theme") + " does not exist";
      console.error(themeerror);
    }
  }
}
main();
