import $ from "../_snowpack/pkg/jquery.js";
import {websitedomain, disablelgin} from "./config.js";
export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
;
function addtotable(id, enable) {
  $("#main").find("tbody").append($("<tr>").attr("class", "tmp").append($("<td>").attr("class", "tmp").append($("<label>").attr("class", "tmp").text(id)).append($("<input>").attr("type", "hidden").attr("name", "ownerid").attr("class", "tmp").attr("value", id))).append($("<td>").append($("<input>").attr("type", "checkbox").attr("name", "activate[]").attr("class", "activate tmp").prop("checked", enable))));
}
;
export function ResetTable() {
  var data = document.getElementsByClassName("tmp");
  for (let item of data) {
    item.remove();
  }
}
export function UpdateUsers() {
  let url = "";
  if (!disablelgin) {
    url = "https://" + websitedomain + "/api/brett/GetUsers.php?session=" + getCookie("sid");
  } else {
    url = "https://" + websitedomain + "/api/brett/GetUsers.php";
  }
  $.get(url, function(data, status) {
    const obj = JSON.parse(data);
    obj.forEach((element) => {
      addtotable(element, 1);
    });
  });
  let url2 = "";
  if (!disablelgin) {
    url2 = "https://" + websitedomain + "/api/brett/DGetUser.php?session=" + getCookie("sid");
  } else {
    url2 = "https://" + websitedomain + "/api/brett/DGetUser.php";
  }
  $.get(url2, function(data, status) {
    const obj = JSON.parse(data);
    obj.forEach((element) => {
      addtotable(element, 0);
    });
  });
}
;
